@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components{
    .bannerIcon{
        @apply w-16 h-16 bg-black bg-opacity-25 text-gray-200 text-xl inline-flex items-center justify-center rounded-md shadow-shadowOne hover:bg-opacity-40 hover:-translate-y-1 transition-all hover:text-designColor cursor-pointer duration-300;
    }
    .resumeLi {
    @apply w-full h-20 bg-black bg-opacity-25 text-xl text-gray-300 flex justify-center cursor-pointer hover:bg-opacity-40 duration-300 items-center border-[1px] rounded-none;
         }
.bgOpacity {
@apply bg-black bg-opacity-40;
                  }
    .contactInput{
        @apply w-full h-12 rounded-lg border-b-[1px] border-b-gray-600 bg-[#191b1e] text-lightText px-4 active:outline-none focus-visible:outline-designColor outline-none focus-visible:border-b-transparent duration-300
    }
.contactTextArea{
    @apply w-full rounded-lg border-b-[1px] border-b-gray-600 bg-[#191b1e] text-lightText px-4 py-2 active:outline-none focus-visible:outline-designColor outline-none focus-visible:border-b-transparent duration-300 resize-none
}
}

.active {
    color: white;
}
.scrollbar-hide::-webkit-scrollbar{
    display: none;
}
.px-8 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
}

@media(max-width: 575px) {
    .pt-sm {
        padding-top:2.5rem !important;
        padding-bottom: 2.5rem !important;
    }
}
/* small screen 575 */

@media(max-width:575px) {
    .p-y-ss {
 padding-top: 1.8rem !important;
 padding-bottom: 1.8rem !important;
    } 
    .mb-sm-8 {
     margin-bottom: 1.5rem !important;
    }
    .gap-2-5 {
        gap: 2.5rem !important;
    }
    .card-wrapper {
flex-direction: column;
    }
    .card-head {
        margin-right: auto;
        margin-bottom: 1rem;
    }

 }

/* screen size 480 */

 @media(max-width:480px) {
    .fs-muneeb {
        font-size: 2rem !important;
    }
.fs-sm {
    font-size: 1.4rem !important;
}
 }